<template>
  <div>
    <div v-for="day in weekDays" v-bind:key="day">
      <b-row no-gutters v-if="Array.isArray(buDays[day])">
        <div class="col-1 mt-2">
          <b-form-checkbox v-model="buDays[day][0]['open']" class="md-primary text-capitalize">
            {{ msg(day.toLowerCase()) }}
          </b-form-checkbox>
        </div>
        <div class="col mb-2 mt-1">
          <vue-ctk-date-time-picker :id="`open-time-picker-${day}`" onlyTime
                                    noClearButton
                                    :label="msg('Open Time')"
                                    :locale="currentFormattedLang"
                                    v-model="buDays[day][0]['openHour']"
                                    format="HH:mm"
                                    formatted="HH:mm"
                                    :disabled="buDays[day][0]['open'] === false"
          />

        </div>
        <div class="col mt-1">
          <vue-ctk-date-time-picker :id="`close-time-picker-${day}`" onlyTime
                                    noClearButton
                                    :label="msg('Close Time')"
                                    :locale="currentFormattedLang"
                                    v-model="buDays[day][0]['closeHour']"
                                    format="HH:mm"
                                    formatted="HH:mm"
                                    :disabled="buDays[day][0]['open'] === false"
          />
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessHours",
  props: {
    value: Object
  },
  data: () => ({}),
  watch: {
    value: {
      handler() {
        this.checkData();
      },
      immediate: true
    }
  },
  computed: {
    weekDays: () => ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
    buDays: {
      get() {
        if (this.value == null)
          return {}
        return {...this.value};
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    checkData() {
      let buDays = {...this.buDays};
      let updated = false;
      this.weekDays.forEach(day => {
        if (Array.isArray(buDays[day]))
          return;
        buDays[day] = [{open:false,openHour:"07:00",closeHour:"19:00"}];
        updated = true;
      })
      if(updated)
        this.buDays = buDays;
    }
  }
}
</script>

<style scoped>


</style>
